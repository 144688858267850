
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import SectionTitle from './../../../components/SectionTitle.vue';
import SectionHeader from './../../../components/SectionHeader.vue';
import {
  imgClientLogoSky,
  imgSwimmer,
  imgClientLogoSportStar,
  imgClientLogoDQuick,
  imgClientLogoArcplanGroup,
  imgClientLogoRefit,
  imgBritishInternationalCollegeOfCairo,
} from './../../../utils/images';

export default defineComponent({
  components: { SectionTitle, SectionHeader },
  setup() {
    const { t } = useI18n();

    return {
      t,
      imgClientLogoSky,
      imgSwimmer,
      imgClientLogoSportStar,
      imgClientLogoDQuick,
      imgClientLogoArcplanGroup,
      imgClientLogoRefit,
      imgBritishInternationalCollegeOfCairo,
    };
  },
});
