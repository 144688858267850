import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "section-bg-image" }
const _hoisted_2 = { class: "btn-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SectionTitle, {
      title: _ctx.t('home.clients.header.description')
    }, null, 8, ["title"]),
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('home.clients.cards.title1'),
      description: _ctx.t('home.clients.cards.description1'),
      image: _ctx.imgClientLogoSky,
      height: "300px",
      width: "80%",
      class: "as-card mb-5 centered img-circle content-centered",
      imgWidth: "10%",
      txtWidth: "85%"
    }, null, 8, ["title", "description", "image"]),
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('home.clients.cards.title7'),
      description: _ctx.t('home.clients.cards.description7'),
      image: _ctx.imgSwimmer,
      height: "300px",
      width: "80%",
      class: "as-card mb-5 centered img-circle content-centered reverse",
      imgWidth: "10%",
      imgHeight: "45%",
      txtWidth: "85%"
    }, null, 8, ["title", "description", "image"]),
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('home.clients.cards.title2'),
      description: _ctx.t('home.clients.cards.description2'),
      image: _ctx.imgClientLogoSportStar,
      height: "300px",
      width: "80%",
      class: "as-card mb-5 centered img-circle content-centered",
      imgWidth: "10%",
      txtWidth: "85%"
    }, null, 8, ["title", "description", "image"]),
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('home.clients.cards.title3'),
      description: _ctx.t('home.clients.cards.description3'),
      image: _ctx.imgClientLogoDQuick,
      height: "300px",
      width: "80%",
      class: "as-card mb-5 centered img-circle content-centered reverse",
      imgWidth: "10%",
      txtWidth: "85%"
    }, null, 8, ["title", "description", "image"]),
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('home.clients.cards.title4'),
      description: _ctx.t('home.clients.cards.description4'),
      image: _ctx.imgClientLogoArcplanGroup,
      height: "300px",
      width: "80%",
      class: "as-card mb-5 centered img-circle content-centered",
      imgWidth: "10%",
      txtWidth: "85%"
    }, null, 8, ["title", "description", "image"]),
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('home.clients.cards.title5'),
      description: _ctx.t('home.clients.cards.description5'),
      image: _ctx.imgClientLogoRefit,
      height: "300px",
      width: "80%",
      class: "as-card mb-5 centered img-circle content-centered reverse",
      imgWidth: "10%",
      txtWidth: "85%"
    }, null, 8, ["title", "description", "image"]),
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('servicesPage.projects.categories.cat1.cards.title4'),
      description: _ctx.t('home.clients.cards.description6'),
      image: _ctx.imgBritishInternationalCollegeOfCairo,
      height: "300px",
      width: "80%",
      class: "as-card mb-5 centered img-circle content-centered",
      imgWidth: "10%",
      imgHeight: "45%",
      txtWidth: "85%"
    }, null, 8, ["title", "description", "image"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_b_button, {
        pill: "",
        variant: "outline-success"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, { to: { name: 'About' } }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('navbar.knowUs')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}